@layer primereact-custom {
  @mixin focus-outline {
    @apply shadow-none outline outline-4 outline-outline-interactive-primary-contained-focus;
  }

  .p-calendar {
    &.rr-warn {
      .p-inputtext {
        @apply border-border-inputControl-warn-default bg-background-inputControl-warn-default text-foreground-inputControl-warn-default;

        &:hover {
          @apply border-border-inputControl-warn-hover;
        }

        &:focus {
          @apply border-border-inputControl-warn-focus outline outline-4 outline-outline-inputControl-warn-focus;
        }
      }
    }

    &.p-invalid {
      .p-inputtext {
        @apply border-border-inputControl-danger-default bg-background-inputControl-danger-default text-foreground-inputControl-danger-default;

        &:hover {
          @apply border-border-inputControl-danger-hover;
        }

        &:focus {
          @apply border-border-inputControl-danger-focus outline outline-4 outline-outline-inputControl-danger-focus;
        }
      }
    }
  }

  .p-datepicker {
    &:not(.p-disabled) {
      table td > span {
        &.p-highlight {
          @apply bg-background-interactive-primary-contained-default text-foreground-interactive-primary-contained-default;

          &:hover {
            @apply bg-background-interactive-primary-contained-hover;
          }
        }

        &:not(.p-highlight):not(.p-disabled) {
          &:focus {
            @include focus-outline;
          }
        }
      }

      .p-datepicker-month:enabled {
        &:hover {
          @apply text-foreground-interactive-primary-outlined-default;
        }
      }

      .p-datepicker-year:enabled {
        &:hover {
          @apply text-foreground-interactive-primary-outlined-default;
        }
      }

      .p-monthpicker {
        .p-monthpicker-month:not(.p-disabled) {
          &.p-highlight {
            @apply bg-background-interactive-primary-contained-default text-foreground-interactive-primary-contained-default;

            &:hover {
              @apply bg-background-interactive-primary-contained-hover;
            }
          }

          &:focus {
            @include focus-outline;
          }
        }
      }

      .p-yearpicker {
        .p-yearpicker-year:not(.p-disabled) {
          &.p-highlight {
            @apply bg-background-interactive-primary-contained-default text-foreground-interactive-primary-contained-default;

            &:hover {
              @apply bg-background-interactive-primary-contained-hover;
            }
          }

          &:focus {
            @include focus-outline;
          }
        }
      }
    }
  }
}
