@layer primereact-custom {
  .p-tooltip {
    .p-tooltip-arrow {
      @apply border-transparent;
    }

    .p-tooltip-text {
      @apply w-fit max-w-xs rounded-md border border-border-primary bg-white px-2 py-3 text-foreground-primary shadow;
    }
  }
}
