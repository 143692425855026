@layer primereact-custom {
  .p-progressbar {
    @apply h-3 rounded-3xl border border-solid border-gray-300 bg-transparent;

    &.p-progressbar-small {
      @apply h-2;
    }

    &.p-progressbar-large {
      @apply h-4;
    }

    &.p-progressbar-primary {
      .p-progressbar-value {
        @apply bg-primary;
      }
    }
  }
}
