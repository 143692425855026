@layer primereact-custom {
  .p-inputtext {
    @apply py-[0.5625rem] text-sm shadow-none;

    &:enabled:not(.p-invalid):not(.rr-warn) {
      @apply border-border-inputControl-primary-default bg-background-inputControl-primary-default text-foreground-inputControl-primary-default;

      &:hover {
        @apply border-border-inputControl-primary-hover;
      }

      &:focus {
        @apply border-border-inputControl-primary-focus outline outline-4 outline-outline-inputControl-primary-focus;
      }
    }

    &.rr-warn {
      @apply border-border-inputControl-warn-default bg-background-inputControl-warn-default text-foreground-inputControl-warn-default;

      &:hover {
        @apply border-border-inputControl-warn-hover;
      }

      &:focus {
        @apply border-border-inputControl-warn-focus outline outline-4 outline-outline-inputControl-warn-focus;
      }
    }

    &.p-invalid {
      @apply border-border-inputControl-danger-default bg-background-inputControl-danger-default text-foreground-inputControl-danger-default;

      &:hover {
        @apply border-border-inputControl-danger-hover;
      }

      &:focus {
        @apply border-border-inputControl-danger-focus outline outline-4 outline-outline-inputControl-danger-focus;
      }
    }
  }
}
