@layer primereact-custom {
  .p-button-group {
    &.p-button-group-stretch {
      @apply flex w-full;

      .p-button {
        @apply flex-1;
      }
    }

    &:not(.p-button-group-stretch) {
      .p-button {
        @apply inline-flex;
      }
    }

    .p-button {
      &:first-of-type {
        @apply rounded-br-none rounded-tr-none;
      }

      &:last-of-type {
        @apply rounded-bl-none rounded-tl-none;
      }

      &.p-highlight {
        @apply border-transparent bg-primary text-white;

        $color: "primary", "danger", "warn", "gray";

        @each $color in $color {
          &.p-button-#{$color} {
            @apply border-border-interactive-#{$color}-contained-default bg-background-interactive-#{$color}-contained-default text-foreground-interactive-#{$color}-contained-default;

            &:not(:disabled) {
              &:hover {
                @apply border-border-interactive-#{$color}-contained-hover bg-background-interactive-#{$color}-contained-hover;
              }

              &:focus {
                @apply outline-outline-interactive-#{$color}-contained-focus outline outline-4;
              }
            }
          }
        }

        &:not(:disabled) {
          &:hover {
            @apply bg-primary-dark;
          }
        }
      }
    }
  }
}
