@font-face {
  font-family: "rock-rabbit";
  src: url("fonts/rock-rabbit.eot?4idrb3");
  src:
    url("fonts/rock-rabbit.eot?4idrb3#iefix") format("embedded-opentype"),
    url("fonts/rock-rabbit.ttf?4idrb3") format("truetype"),
    url("fonts/rock-rabbit.woff?4idrb3") format("woff"),
    url("fonts/rock-rabbit.svg?4idrb3#rock-rabbit") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="rr-"],
[class*=" rr-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "rock-rabbit" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rr-badge:before {
  content: "\e901";
}
.rr-cash:before {
  content: "\e902";
}
.rr-warning:before {
  content: "\e903";
}
.rr-sparkles:before {
  content: "\e904";
}
.rr-play:before {
  content: "\e905";
}
.rr-money-calendar:before {
  content: "\e906";
}
.rr-no-water:before {
  content: "\e907";
}
