@layer primereact-custom {
  .p-inputnumber {
    &.p-invalid {
      .p-inputnumber-input {
        &:enabled {
          @apply border-border-inputControl-danger-default bg-background-inputControl-danger-default text-foreground-inputControl-danger-default;

          &:hover {
            @apply border-border-inputControl-danger-hover;
          }

          &:focus {
            @apply border-border-inputControl-danger-focus;
          }
        }
      }
    }

    .p-inputnumber-button {
      @apply rounded-l-md rounded-r-md;
    }
  }
}
