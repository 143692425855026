@layer primereact-custom {
  .p-checkbox {
    .p-checkbox-box {
      @apply border-border-primary;
    }

    &.p-highlight {
      .p-checkbox-box {
        @apply border-primary bg-primary;
      }
    }

    &:not(.p-disabled) {
      &:has(.p-checkbox-input:hover) {
        .p-checkbox-box {
          @apply border-primary;
        }
      }

      &:has(.p-checkbox-input:focus-visible) {
        .p-checkbox-box {
          @apply border-primary shadow-none;
        }
      }
    }
  }

  .p-checkbox-block {
    &:hover {
      .p-checkbox {
        &:not(.p-disabled) {
          .p-checkbox-box {
            @apply border-primary #{!important};
          }
        }
      }
    }
  }
}
