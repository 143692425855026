@layer primereact-custom {
  .p-autocomplete {
    @apply max-w-full;

    .p-inputtext {
      @apply w-full;
    }

    &.rr-warn {
      .p-inputtext {
        @apply border-border-inputControl-warn-default bg-background-inputControl-warn-default text-foreground-inputControl-warn-default #{!important};

        &:hover {
          @apply border-border-inputControl-warn-hover #{!important};
        }

        &.p-focus {
          @apply border-border-inputControl-warn-focus outline outline-4 outline-outline-inputControl-warn-focus #{!important};
        }
      }
    }

    &.p-invalid {
      .p-inputtext {
        @apply border-border-inputControl-danger-default bg-background-inputControl-danger-default text-foreground-inputControl-danger-default #{!important};

        &:hover {
          @apply border-border-inputControl-danger-hover #{!important};
        }

        &.p-focus {
          @apply border-border-inputControl-danger-focus outline outline-4 outline-outline-inputControl-danger-focus #{!important};
        }
      }
    }
  }

  .p-autocomplete-panel {
    @apply w-min max-w-full;

    .p-autocomplete-items-wrapper {
      @apply rounded-lg border border-gray-300;
    }

    .p-autocomplete-items {
      @apply p-0;

      .p-autocomplete-item {
        @apply m-[0.375rem] text-wrap rounded-xl px-[0.625rem] py-[0.375rem] text-base font-normal;

        &.p-highlight {
          @apply border border-primary-100 bg-gray-200 text-inherit;

          &:hover {
            @apply bg-gray-100;
          }
        }

        &[data-pc-section="emptymessage"] {
          @apply cursor-default bg-transparent opacity-60;
        }
      }
    }
  }
}
