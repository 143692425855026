@layer primereact-custom {
  .p-dropdown {
    @apply flex items-center gap-3 rounded-md border-border-inputControl-primary-default bg-background-inputControl-primary-default px-3 py-[0.5625rem] text-foreground-inputControl-primary-default shadow-none;

    &:hover {
      @apply border-border-inputControl-primary-hover;
    }

    &.p-focus {
      @apply border-border-inputControl-primary-focus outline outline-4 outline-outline-inputControl-primary-focus;
    }

    .p-dropdown-label {
      @apply p-0 text-sm font-normal;
    }

    .p-dropdown-trigger {
      @apply w-5;
    }

    &.rr-warn {
      @apply border-border-inputControl-warn-default bg-background-inputControl-warn-default text-foreground-inputControl-warn-default;

      &:hover {
        @apply border-border-inputControl-warn-hover;
      }

      &.p-focus {
        @apply border-border-inputControl-warn-focus outline outline-4 outline-outline-inputControl-warn-focus;
      }
    }

    &.p-invalid {
      @apply border-border-inputControl-danger-default bg-background-inputControl-danger-default text-foreground-inputControl-danger-default;

      &:hover {
        @apply border-border-inputControl-danger-hover;
      }

      &.p-focus {
        @apply border-border-inputControl-danger-focus outline outline-4 outline-outline-inputControl-danger-focus;
      }
    }
  }

  .p-dropdown-panel {
    @apply w-min max-w-full;

    .p-dropdown-items-wrapper {
      @apply rounded-lg border border-gray-300;

      .p-dropdown-items {
        @apply p-0;

        .p-dropdown-item {
          @apply m-[0.375rem] text-wrap rounded-xl px-[0.625rem] py-[0.375rem] text-base font-normal;

          .p-dropdown-item-label {
            @apply w-full;
          }

          &.p-highlight {
            @apply border border-primary-100 bg-gray-200 text-inherit;

            &:hover {
              @apply bg-gray-100;
            }
          }
        }
      }
    }
  }
}
