@layer primereact-custom {
  .p-menu {
    @apply rounded-xl border border-solid border-gray-300 p-0;

    .p-menuitem {
      &.p-focus {
        @apply bg-transparent;

        .p-menuitem-content {
          @apply bg-gray-200;
        }
      }

      &:hover {
        @apply bg-transparent;

        .p-menuitem-content {
          @apply bg-gray-100;
        }
      }

      .p-menuitem-content {
        @apply m-[0.375rem] rounded-xl px-[0.625rem] py-[0.375rem] text-base font-normal text-inherit;

        .p-menuitem-link {
          @apply flex items-center gap-2 px-[0.625rem] py-[0.375rem] text-inherit;

          .p-menuitem-text {
            @apply text-inherit;
          }
        }
      }
    }
  }
}
