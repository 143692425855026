@layer markdown {
  .markdown {
    h4 {
      @apply mb-2 text-lg font-semibold;
    }

    ol {
      @apply list-decimal pl-5;
    }

    ul {
      @apply list-disc pl-5;
    }

    p {
      @apply mb-2 text-foreground-secondary;
    }

    li {
      @apply mb-1 text-foreground-secondary;
    }

    a {
      @apply text-primary underline;
    }
  }
}
