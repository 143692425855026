@layer primereact-custom {
  .react-tel-input {
    .selected-flag {
      @apply border-border-inputControl-primary-default bg-background-inputControl-primary-default text-foreground-inputControl-primary-default;

      &:hover {
        @apply border-border-inputControl-primary-hover;
      }

      &:focus {
        @apply border-border-inputControl-primary-focus outline outline-4 outline-outline-inputControl-primary-focus #{!important};
      }
    }

    &.rr-invalid {
      .selected-flag {
        @apply border-border-inputControl-danger-default bg-background-inputControl-danger-default text-foreground-inputControl-danger-default;

        &:hover {
          @apply border-border-inputControl-danger-hover;
        }

        &:focus {
          @apply border-border-inputControl-danger-focus outline outline-4 outline-outline-inputControl-danger-focus #{!important};
        }
      }
    }
  }
}
