@layer primereact-custom {
  .p-dialog-mask {
    @apply flex-col;
  }

  .p-datatable-loading-overlay {
    @apply bg-black/5;
  }

  .p-dialog {
    @apply max-w-[calc(100%-1rem)];

    .p-dialog-header {
      .p-dialog-title {
        @apply grow text-lg;
      }

      .p-dialog-header-icon {
        &:focus-visible {
          @apply shadow-none outline outline-1 outline-primary;
        }
      }
    }

    .p-dialog-content {
      @apply pt-1;
    }
  }
}
