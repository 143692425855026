@layer primereact-custom {
  .p-multiselect {
    @apply flex items-center gap-3 rounded-md border-border-inputControl-primary-default bg-background-inputControl-primary-default px-3 py-[0.4375rem] text-foreground-inputControl-primary-default shadow-none;

    .p-multiselect-trigger {
      @apply w-5;
    }

    &:hover {
      @apply border-border-inputControl-primary-hover;
    }

    &.p-focus {
      @apply border-border-inputControl-primary-focus outline outline-4 outline-outline-inputControl-primary-focus;

      &.rr-warn {
        @apply border-border-inputControl-warn-focus outline outline-4 outline-outline-inputControl-warn-focus;
      }

      &.p-invalid {
        @apply border-border-inputControl-danger-focus outline outline-4 outline-outline-inputControl-danger-focus;
      }
    }

    &.p-multiselect-chip {
      .p-multiselect-label {
        @apply p-0;
      }

      .p-multiselect-token {
        @apply px-[0.75rem] py-0;
      }
    }

    &.rr-warn {
      @apply border-border-inputControl-warn-default bg-background-inputControl-warn-default text-foreground-inputControl-warn-default;

      &:hover {
        @apply border-border-inputControl-warn-hover;
      }
    }

    &.p-invalid {
      @apply border-border-inputControl-danger-default bg-background-inputControl-danger-default text-foreground-inputControl-danger-default;

      &:hover {
        @apply border-border-inputControl-danger-hover;
      }
    }
  }

  .p-multiselect-panel {
    .p-multiselect-header {
      @apply py-1;

      .p-multiselect-close {
        &:focus-visible {
          @apply shadow-none outline outline-4 outline-outline-interactive-primary-text-focus;
        }
      }
    }

    .p-multiselect-items-wrapper {
      @apply rounded-md border border-gray-300;

      .p-multiselect-items {
        @apply p-0;

        .p-multiselect-item {
          @apply m-[0.375rem] rounded-xl px-[0.625rem] py-[0.375rem] text-base font-normal;

          .p-multiselect-item-label {
            @apply w-full;
          }

          &.p-highlight {
            @apply border border-primary-100 bg-gray-200 text-inherit;

            &:hover {
              @apply bg-gray-100;
            }
          }
        }
      }
    }
  }
}
