@layer primereact-custom {
  .p-radiobutton {
    .p-radiobutton-box {
      @apply border-border-primary;
    }

    &.p-highlight {
      .p-radiobutton-box {
        @apply border-primary bg-primary;
      }
    }

    &:not(.p-disabled) {
      &:has(.p-radiobutton-input:hover) {
        .p-radiobutton-box {
          @apply border-primary;
        }
      }

      &:has(.p-radiobutton-input:focus-visible) {
        .p-radiobutton-box {
          @apply border-primary shadow-none;
        }
      }
    }
  }

  .p-radiobutton-block {
    &:hover {
      .p-radiobutton {
        &:not(.p-disabled) {
          .p-radiobutton-box {
            @apply border-primary #{!important};
          }
        }
      }
    }
  }
}
