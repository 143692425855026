@layer primereact-custom {
  .p-button {
    @apply flex;

    &.p-button-readonly {
      @apply opacity-100;
    }

    @apply justify-center border text-sm;

    &:focus {
      @apply shadow-none;
    }

    .p-button-label {
      @apply font-semibold transition-none;
    }

    &:not(.p-button-icon-only) {
      @apply px-4 py-[0.625rem];

      &.p-button-sm {
        @apply px-[0.875rem] py-2;
      }

      &.p-button-squash {
        @apply px-0;
      }

      &.p-button-flat {
        @apply py-0;
      }
    }

    &.p-button-icon-only {
      @apply w-initial p-2;

      &.p-button-text {
        @apply border-gray-300;

        &:hover {
          @apply bg-inherit;
        }

        &:active {
          @apply bg-inherit;
        }

        &.p-button-primary {
          @apply text-primary;

          &.rr-activated {
            @apply border-primary;
          }
        }
      }

      .p-button-label {
        display: none;
      }

      &.p-button-squash {
        @apply px-0;
      }

      &.p-button-flat {
        @apply py-0;
      }
    }

    $color: "primary", "success", "info", "danger", "warn", "gray";
    $variant: "contained", "outlined", "text", "link";

    @each $color in $color {
      @each $variant in $variant {
        &.p-button-#{$color} {
          &.p-button-#{$variant} {
            @apply border-border-interactive-#{$color}-#{$variant}-default bg-background-interactive-#{$color}-#{$variant}-default text-foreground-interactive-#{$color}-#{$variant}-default;

            &:not(:disabled) {
              &:hover {
                @apply border-border-interactive-#{$color}-#{$variant}-hover bg-background-interactive-#{$color}-#{$variant}-hover;
              }

              &:focus {
                @apply outline-outline-interactive-#{$color}-#{$variant}-focus outline outline-4;
              }
            }
          }
        }
      }
    }
  }
}
