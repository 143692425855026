@layer primereact-custom {
  .p-inline-message {
    @apply border;

    $severity: "success", "info", "warn", "error";

    @each $severity in $severity {
      &.p-inline-message-#{$severity} {
        @apply border-border-system-#{$severity} bg-background-system-#{$severity} text-foreground-system-#{$severity};
      }
    }
  }
}
