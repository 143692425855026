@layer primereact-custom {
  .p-badge {
    @apply h-initial w-fit rounded-md border px-2 py-[0.1875rem] text-sm font-normal;

    &.p-badge-outlined {
      &.p-badge-green {
        @apply border-badge-green-a bg-badge-green-b text-badge-green-a;
      }

      &.p-badge-red {
        @apply border-badge-red-a bg-badge-red-b text-badge-red-a;
      }

      &.p-badge-blue {
        @apply border-badge-blue-a bg-badge-blue-b text-badge-blue-a;
      }

      &.p-badge-purple {
        @apply border-badge-purple-a bg-badge-purple-b text-badge-purple-a;
      }

      &.p-badge-yellow {
        @apply border-badge-yellow-a bg-badge-yellow-b text-badge-yellow-a;
      }

      &.p-badge-gray {
        @apply border-badge-gray-a bg-badge-gray-b text-badge-gray-a;
      }
    }

    // I'm hoping to remove this variant in the future
    &.p-badge-contained {
      @apply border-transparent text-black;

      &.p-badge-green {
        background-color: #ddf5d4;
      }

      &.p-badge-red {
        background-color: #ffdac7;
      }

      &.p-badge-blue {
        background-color: #cdf0f9;
      }

      &.p-badge-purple {
        background-color: #f7f0f7;
      }

      &.p-badge-yellow {
        background-color: #fef0d6;
      }

      &.p-badge-gray {
        background-color: #f5f5f5;
      }
    }

    // I'm hoping to remove this variant in the future
    &.p-badge-outlined-secondary {
      @apply text-black;

      &.p-badge-green {
        @apply border-badge-green-a bg-badge-green-b;
      }

      &.p-badge-red {
        @apply border-badge-red-a bg-badge-red-b;
      }

      &.p-badge-blue {
        @apply border-badge-blue-a bg-badge-blue-b;
      }

      &.p-badge-purple {
        @apply border-badge-purple-a bg-badge-purple-b;
      }

      &.p-badge-yellow {
        @apply border-badge-yellow-a bg-badge-yellow-b;
      }

      &.p-badge-gray {
        @apply border-badge-gray-a bg-badge-gray-b;
      }
    }
  }
}
