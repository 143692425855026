@layer primereact-custom {
  .p-datatable {
    .p-column-filter-menu {
      @apply ml-3;
    }

    .p-column-filter-menu-button {
      @apply size-5;
    }

    .p-datatable-emptymessage {
      @apply font-medium italic text-foreground-tertiary;
    }

    &.p-datatable-striped {
      .p-datatable-tbody {
        > tr {
          @apply bg-white;

          .p-row-odd {
            @apply bg-white;
          }

          &:nth-child(even) {
            @apply bg-gray-50;
          }
        }
      }
    }

    .p-datatable-wrapper {
      @apply border-gray-300 bg-background-primary;
    }

    .p-datatable-header {
      @apply rounded-t-lg border border-gray-300 bg-white py-0 py-3 text-base font-normal;
    }

    .p-datatable-thead {
      @apply z-50 -translate-y-px;

      > tr {
        @apply bg-gray-50;

        > th {
          @apply border-t border-gray-300 bg-background-primary px-6 py-3 text-sm font-medium text-foreground-tertiary;
        }
      }
    }

    .p-datatable-tbody {
      > tr {
        > td {
          @apply px-6 py-3 align-top text-sm;
        }

        &:last-child {
          > td {
            @apply border-b-transparent;
          }
        }
      }
    }

    .p-datatable-footer {
      @apply rounded-b-lg border-b border-l border-r border-t px-0;
    }

    .p-paginator {
      @apply mt-2 rounded-lg border border-gray-300 p-3;

      .p-paginator-first,
      .p-paginator-prev,
      .p-paginator-next,
      .p-paginator-last {
        @apply size-10 text-sm;

        min-width: initial;
        min-height: initial;
      }

      .p-paginator-pages {
        .p-paginator-page {
          @apply size-10 text-sm;

          min-width: initial;
          min-height: initial;
        }
      }
    }

    &:not(.rr-has-header):not(.rr-has-footer) {
      .p-datatable-wrapper {
        @apply rounded-lg border border-gray-300;
      }

      .p-datatable-thead {
        > tr {
          > th {
            @apply border-t-transparent;
          }
        }
      }
    }

    &.rr-has-header:not(.rr-has-footer) {
      .p-datatable-wrapper {
        @apply rounded-b-lg border-b border-l border-r border-gray-300;
      }
    }

    &.rr-has-footer:not(.rr-has-header) {
      .p-datatable-wrapper {
        @apply rounded-t-lg border-l border-r border-t border-gray-300;
      }

      .p-datatable-thead {
        > tr {
          > th {
            @apply border-t-transparent;
          }
        }
      }
    }

    &.rr-has-header.rr-has-footer {
      .p-datatable-wrapper {
        @apply border-l border-r border-gray-300;
      }
    }
  }

  .p-treetable {
    .p-treetable-wrapper {
      @apply border-gray-300 bg-background-primary;
    }

    .p-treetable-thead {
      @apply z-50 -translate-y-px;

      > tr {
        @apply bg-gray-50;

        > th {
          @apply border-t border-gray-300 bg-background-primary px-6 py-3 text-sm font-medium text-foreground-tertiary;
        }
      }
    }

    .p-treetable-tbody {
      > tr {
        > td {
          @apply px-6 py-3 align-top text-sm;
        }

        &:last-child {
          > td {
            @apply border-b-transparent;
          }
        }
      }
    }

    .p-treetable-footer {
      @apply rounded-b-lg border-b border-l border-r border-t px-0;
    }

    .p-treetable-toggler {
      @apply align-top;
    }

    &:not(.rr-has-header):not(.rr-has-footer) {
      .p-treetable-wrapper {
        @apply rounded-lg border border-gray-300;
      }
    }

    &.rr-has-header:not(.rr-has-footer) {
      .p-treetable-wrapper {
        @apply rounded-b-lg border-b border-l border-r border-gray-300;
      }
    }

    &.rr-has-footer:not(.rr-has-header) {
      .p-treetable-wrapper {
        @apply rounded-t-lg border-l border-r border-t border-gray-300;
      }

      .p-treetable-thead {
        > tr {
          > th {
            @apply border-t-transparent;
          }
        }
      }
    }

    &.rr-has-header.rr-has-footer {
      .p-treetable-wrapper {
        @apply border-l border-r border-gray-300;
      }
    }
  }
}
