@layer tailwind-base, markdown, primereact, tailwind-utilities, primereact-custom;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

// TODO: Deprecate all of these --rr__xxx colors
:root {
  --rr__color__gray-a--6284968128: #f7f7f7;
  --rr__color__gray-b--6284968128: #d7d7d7;
  --rr__color__gray-c--6284968128: #797579;
  --rr__color__bg-dark: #f4f4f4;
  --rr__color__bg-light-dimmer: rgba(252, 252, 252, 0.6);
  --rr__color__green-primary: #175d62;
  --rr__color__border: #d7d7d7;
  --rr__color__error: #d92d20;
}

body {
  background: #ffffff;
  color: #100010;
}
