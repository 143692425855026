@layer primereact-custom {
  .p-inputotp {
    .p-inputotp-input {
      @apply size-10 p-0 text-lg;
    }

    &.p-inputotp-lg {
      .p-inputotp-input {
        @apply size-14 p-0 text-2xl;
      }
    }
  }
}
